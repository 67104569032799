import React, { useEffect, useState } from "react";
import { FindTypeAdd } from "../api/api";
import { Spinner } from "@telegram-apps/telegram-ui";
import { Multiselect } from "@telegram-apps/telegram-ui";

const TypeAddInput = ({ onSelectChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  function transformArray(typeAdd) {
    if (typeAdd) {
      return typeAdd.map((type) => ({
        id: type.id,
        label: type.name,
        value: type.name,
      }));
    }
  }

  async function getTypeAddList() {
    let res = await FindTypeAdd(false);
    console.log(transformArray(res));

    setOptions(transformArray(res));
  }

  useEffect(() => {
    getTypeAddList();
  }, []);

  const handleSelectionChange = (selected) => {
    setSelectedOptions(selected);
    if (onSelectChange) {
      onSelectChange(selected); // Pass the single selected item to the callback
    }
  };
  return (
    <>
      <Multiselect
        value={selectedOptions}
        options={options}
        placeholder="Выбери тип реклаы"
        closeDropdownAfterSelect={true}
        onChange={(selected) => handleSelectionChange(selected)}
        // renderOption={(option) => (
        //   <span
        //     style={{
        //       zIndex: 9999, // Ensuring this option is layered above modal background
        //     }}
        //   >
        //     {option.label}
        //   </span>
        // )}
      />
    </>
  );
};
export default TypeAddInput;
