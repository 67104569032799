import React, { useEffect, useState } from "react";
import { FindUser } from "../api/api";
import { Spinner } from "@telegram-apps/telegram-ui";
import { Multiselect } from "@telegram-apps/telegram-ui";

const UserFinderInput = ({ onSingleSelectChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  function transformArray(users) {
    if (users) {
      return users.map((user) => ({
        id: user.id,
        tg_user_id: user.tg_user_id,
        label: "@" + user.tg_user_name,
        value: user.tg_user_name,
      }));
    }
  }

  async function getUsersList() {
    let res = await FindUser();
    setOptions(transformArray(res));
    console.log(transformArray(res));
  }

  useEffect(() => {
    getUsersList();
  }, []);

  const handleSelectionChange = (selected) => {
    const singleSelection = selected.slice(-1); // Keep only the latest selected option
    setSelectedOptions(singleSelection);
    if (onSingleSelectChange) {
      onSingleSelectChange(singleSelection[0]); // Pass the single selected item to the callback
    }
  };
  return (
    <>
      <div>
        <Multiselect
          value={selectedOptions}
          options={options}
          placeholder="Выбери пользователя"
          closeDropdownAfterSelect={true}
          onChange={(selected) => handleSelectionChange(selected)}
          // renderOption={(option) => (
          //   <div style={{ zIndex: "9999 !important" }}>{option.label}</div>
          // )}
        />
      </div>
    </>
  );
};
export default UserFinderInput;
