import styled from "styled-components";

const StyledTaskEditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 20px 22px 16px;
`;

// Компонента-обертка
const SwithWrapper = ({ children }) => {
  return <StyledTaskEditorWrapper>{children}</StyledTaskEditorWrapper>;
};

export default SwithWrapper;
