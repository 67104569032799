import React, { useEffect, useState } from "react";
import {
  Spinner,
  Cell,
  Text,
  Card,
  Button,
  Info,
} from "@telegram-apps/telegram-ui";
import { FindUser } from "../api/api";
import styled from "styled-components";
import BadgeCastom from "../components/BadgeCastom";
import ContentWrapper from "../components/ContentWrapper";
import UserFinderInput from "../components/UserFinderInput";
import CardTextWrapper from "../components/CardTextWrapper";
import EditeUserPnnelModal from "../components/EditeUserPnnelModal";
const StyledCardTask = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.04), 0 0 2px 1px rgba(0, 0, 0, 0.02);
  background: var(--tgui--card_bg_color);
  flex-direction: column;
  row-gap: 0.5rem;
`;
const UsersPanel = () => {
  const [users, setUsers] = useState([]);
  const [findeUser, setFindeuser] = useState(null);

  async function getUsersList() {
    let res = await FindUser();
    setUsers(res);
  }

  useEffect(() => {
    getUsersList();
  }, []);

  let sortedUsers = findeUser
    ? [
        ...users.filter((user) => user.id === findeUser), // Пользователь с ID findeUser
        ...users.filter((user) => user.id !== findeUser), // Остальные пользователи
      ]
    : users;

  function replaceUsertById(newElement) {
    console.log(newElement);
    if (newElement?.id) {
      setUsers((prevUsers) =>
        prevUsers.map((item) => (item.id === newElement.id ? newElement : item))
      );
    }

    // console.log(newUser);

    // return sortedUsers.map((item) => (item.id === id ? newElement : item));
  }
  return (
    <>
      <ContentWrapper>
        <UserFinderInput onSingleSelectChange={(e) => setFindeuser(e?.id)} />
        {sortedUsers && (
          <>
            {sortedUsers.map((user) => {
              return (
                <>
                  <StyledCardTask key={user.id}>
                    <CardTextWrapper type="text">
                      <Text weight="1">@{user.tg_user_name} </Text> <br />
                      <Text weight="3">
                        id:{user?.id ? user?.id : "п</Text>усто"}{" "}
                      </Text>
                      <br />
                      <Text weight="3">
                        ФИО: {user?.full_name ? user?.full_name : "пусто"}
                      </Text>
                      <br />
                      <Text weight="3">
                        Адрес: {user?.address ? user?.address : "пусто"}
                      </Text>
                      <br />
                      <Text weight="3">
                        Роль: {user?.role ? user?.role : "пусто"}
                      </Text>
                      <br />
                      <Text weight="3">
                        Телефон: {user?.phone ? user.phone : "пусто"}
                      </Text>
                      <br />
                      <Text weight="3">
                        Имя в телеграмм: @
                        {user?.tg_user_name ? user.tg_user_name : "пусто"}{" "}
                      </Text>
                      <br />
                      <Text weight="3">
                        Id в телеграмм:{" "}
                        {user?.tg_user_id ? user.tg_user_id : "пусто"}
                      </Text>
                      <br />
                      <Text weight="3">
                        Роль подтверждена:{" "}
                        {user?.validate_role ? (
                          <BadgeCastom style="good">Да</BadgeCastom>
                        ) : (
                          <BadgeCastom style="bad">Нет</BadgeCastom>
                        )}
                      </Text>
                    </CardTextWrapper>
                    <br />
                    <EditeUserPnnelModal
                      onChangeUser={(e) => replaceUsertById(e)}
                      state={user}
                      tg_user_id={user?.tg_user_id}
                    />
                  </StyledCardTask>
                </>
              );
            })}
          </>
        )}

        {/* {JSON.stringify(users)} */}
      </ContentWrapper>
    </>
  );
};

export default UsersPanel;
