import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Tabbar,
  Section,
  Cell,
  ButtonCell,
  Modal,
} from "@telegram-apps/telegram-ui";
import { RxCrossCircled } from "react-icons/rx";
import { LuUser } from "react-icons/lu";
import { LuMenu } from "react-icons/lu";
import {
  HOME_PATH,
  TYPE_ADD,
  TASK_USER,
  USRES_SETTINGS,
  ADRESSES_SETTINGS,
  USER_PANEL,
} from "../constants/routes";
import { observer } from "mobx-react-lite";
import { userStore } from "../stores/UserStore";
import { ADMIN, USER } from "../constants/roles";
import { FaCube, FaWpforms, FaMapLocation } from "react-icons/fa6";

const Menu = observer(() => {
  const [user, setUser] = useState(userStore.user);
  const location = useLocation();
  const [path, setPath] = useState(HOME_PATH);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const goToContact = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setUser(userStore.user);
  }, [userStore.user]);

  useEffect(() => {
    console.log(
      "URL изменился:",
      location.pathname + location.search + location.hash
    );
    setPath(location.pathname);
  }, [location]);

  // Функция для проверки наличия пользователя и его роли
  const isUserRole = (role) => user && user.role === role;

  return (
    <>
      <div style={{ position: "absolute", zIndex: "2" }}>
        <Tabbar>
          <Tabbar.Item
            text="Задачи"
            onClick={() => goToContact(HOME_PATH)}
            selected={path === HOME_PATH}
          >
            <FaCube size={16}></FaCube>
          </Tabbar.Item>
          <Tabbar.Item
            text="Меню"
            onClick={() => setOpen(true)}
            selected={open}
          >
            <LuMenu size={16}></LuMenu>
          </Tabbar.Item>
          <Modal onOpenChange={(open) => setOpen(open)} open={open}>
            <Section
              footer={"Версия: s1.0.1 id: " + userStore?.user?.tg_user_id}
              header="Меню"
            >
              <ButtonCell
                before={<RxCrossCircled size={24} />}
                onClick={() => {
                  setOpen(false);
                }}
                interactiveAnimation="background"
                mode="destructive"
              >
                Закрыть
              </ButtonCell>

              {isUserRole(ADMIN) && (
                <>
                  <Cell
                    before={<FaWpforms size={24} />}
                    hovered={path === TYPE_ADD}
                    onClick={() => {
                      goToContact(TYPE_ADD);
                      setOpen(false);
                    }}
                  >
                    Типы рекламы
                  </Cell>
                  <Cell
                    before={<FaCube size={24} />}
                    hovered={path === USER_PANEL}
                    onClick={() => {
                      goToContact(USER_PANEL);
                      setOpen(false);
                    }}
                  >
                    Управление пользователями
                  </Cell>
                  <Cell
                    before={<FaMapLocation size={24} />}
                    hovered={path === ADRESSES_SETTINGS}
                    onClick={() => {
                      goToContact(ADRESSES_SETTINGS);
                      setOpen(false);
                    }}
                  >
                    Адреса
                  </Cell>
                  {/* <Cell
                    before={<FaCube size={24} />}
                    hovered={path === TASK_ADMIN}
                    onClick={() => {
                      goToContact(TASK_ADMIN);
                      setOpen(false);
                    }}
                  >
                    Задачи
                  </Cell> */}
                  {/* <Cell
                    before={<FaCube size={24} />}
                    hovered={path === CREAT_TASK}
                    onClick={() => {
                      goToContact(CREAT_TASK);
                      setOpen(false);
                    }}
                  >
                    Создать задачу
                  </Cell> */}
                  {/* */}
                  {/*
                    Типы рекламы
                  </Cell> */}
                  {/* <Cell
                    before={<FaCube size={24} />}
                    hovered={path === TASK_USER}
                    onClick={() => {
                      goToContact(TASK_USER);
                      setOpen(false);
                    }}
                  >
                    Задачи User
                  </Cell> */}
                </>
              )}

              {isUserRole(USER) && (
                <>
                  <Cell
                    before={<FaCube size={24} />}
                    hovered={path === "/"}
                    onClick={() => {
                      goToContact("/");
                      setOpen(false);
                    }}
                  >
                    Задачи
                  </Cell>
                </>
              )}
              <Cell
                before={<LuUser size={24} />}
                hovered={path === USRES_SETTINGS}
                onClick={() => {
                  goToContact(USRES_SETTINGS);
                  setOpen(false);
                }}
              >
                Профиль
              </Cell>
            </Section>
          </Modal>
        </Tabbar>
      </div>
    </>
  );
});
export default Menu;
