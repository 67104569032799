import React, { useEffect, useState } from "react";
import { uploadedStore } from "../stores/Uploaded";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { IN_PROGRESS, UNDER_REVIEW } from "../constants/status";
import { taskStore } from "../stores/TaskStore";
import { FaRegCircleCheck, FaRegCircle } from "react-icons/fa6";
import {
  Title,
  Text,
  Button,
  Section,
  Caption,
  Modal,
} from "@telegram-apps/telegram-ui";
import ContentWrapper from "./ContentWrapper";
import UploadedFiles from "./UploadedFiles";
import { changeTasksStatus } from "../api/api";
import TypeAddsImages from "../components/TypeAddsImages";
import StyledCardTaskWrapper from "../wrappers/CardTaskWrapper";
import TaskAdminModalSwith from "./TaskAdminModalSwith";
import BadgeCastom from "./BadgeCastom";
import CastomCamera from "./Camera";

const TaskEditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

const TaskEditorWrapperText = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTask = observer(({ state, tg_user_id }) => {
  const [dataState, setDataState] = useState(state);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("UPDATE state");
    setDataState(state);
  }, [state]);

  function findImagesByTypeAddId(typeAddId, data) {
    if (data != null) {
      const item = data.find((element) => element.typeAddId === typeAddId);
      // Если элемент найден, возвращаем массив images, иначе null
      return item ? item.images : [];
    }
    return [];
  }
  function findElementByTypeAddId(typeAddId, data) {
    if (data != null) {
      const item = data.find((element) => element.typeAddId === typeAddId);
      // Если элемент найден, возвращаем массив images, иначе null
      return item ? item : false;
    }
    return false;
  }
  function getDayMonthYear(dateStr) {
    const date = new Date(dateStr);

    const day = date.getDate(); // Получаем день месяца
    const month = date.getMonth() + 1; // Получаем месяц (месяцы начинаются с 0)
    const year = date.getFullYear(); // Получаем год

    return `${day}-${month}-${year}`;
  }
  async function handelchangeTasksStatus(status) {
    let newStatus = await changeTasksStatus(tg_user_id, dataState.id, status);
    console.log(newStatus);
    if (newStatus) taskStore.updateTaskStatus(dataState.id, status);
  }

  return (
    <>
      <StyledCardTaskWrapper>
        <Caption weight="3">
          Статус:{" "}
          {dataState?.completed ? (
            <BadgeCastom style="good">Готова</BadgeCastom>
          ) : (
            <BadgeCastom style="bad">Не завершена</BadgeCastom>
          )}{" "}
          <BadgeCastom>{dataState?.status}</BadgeCastom>
        </Caption>
        <Caption level="1" weight="3">
          id: {dataState.id}
        </Caption>
        <Caption level="1" weight="3">
          Автор: @{dataState.creator_user_name}
        </Caption>
        <Title level="3" weight="1">
          Адрес: {dataState.address}
        </Title>
        <Title level="3" weight="1">
          Дата: {getDayMonthYear(dataState?.createdAt || null)}
        </Title>
        <hr
          style={{ color: "var(--tgui--secondary_hint_color)", width: "100%" }}
        />
        <Title level="1" weight="3">
          Задания
        </Title>

        {dataState.typeAdds.map((element) => {
          return (
            <React.Fragment key={element.id}>
              {/* <Text weight="1">
                {findElementByTypeAddId(element.id, dataState.task_result)
                  .passed ? (
                  <>
                    <FaRegCircleCheck />{" "}
                  </>
                ) : (
                  <>
                    <FaRegCircle />
                  </>
                )}
              </Text> */}
              <Text weight="1">{element.name}</Text>
              <Text weight="3">{element.descripton}</Text>
              <TypeAddsImages
                state={findImagesByTypeAddId(element.id, dataState.task_result)}
              />
            </React.Fragment>
          );
        })}
        <Button
          disabled={!dataState.typeAdds.length ? true : false}
          onClick={() => {
            setOpen(true);
          }}
        >
          Заполнить задачу
        </Button>
        <Button
          disabled={dataState.status === IN_PROGRESS}
          onClick={() => {
            handelchangeTasksStatus(IN_PROGRESS);
          }}
        >
          Взять в работу
        </Button>
        <Button
          disabled={dataState.status === UNDER_REVIEW}
          onClick={() => {
            handelchangeTasksStatus(UNDER_REVIEW);
          }}
        >
          Отправить на рсмотрение
        </Button>
        <Modal
          onOpenChange={(open) => {
            setOpen(open);
            if (!open) uploadedStore.clearAll();
          }}
          open={open}
        >
          <Section footer="Версия: s1.0.1" header="Выполнение таски">
            <ContentWrapper>
              {dataState.typeAdds.map((element, index) => {
                return (
                  <>
                    <TaskAdminModalSwith
                      key={element.id}
                      task={element}
                      task_result={dataState.task_result}
                      taskId={state.id}
                    />
                    <CastomCamera
                      tg_user_id={tg_user_id}
                      typeAddId={element.id}
                      taskId={state.id}
                      // onSingleAddImages={(e) => console.log(e)}
                    />
                    <UploadedFiles
                      tg_user_id={tg_user_id}
                      typeAddId={element.id}
                      taskId={state.id}
                    />
                  </>
                );
              })}
            </ContentWrapper>
          </Section>
        </Modal>
      </StyledCardTaskWrapper>
    </>
  );
});
export default CardTask;
