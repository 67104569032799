import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BadgeCastom from "./BadgeCastom";
import { GET_IMAGE } from "../api/api";
import { Image, Text } from "@telegram-apps/telegram-ui";
const StyleTypeAddsImages = styled.div`
  row-gap: 1rem;
  padding: 1rem 1rem 6rem 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
`;
const StyledImagePrewie = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

// Компонента-обертка
const TypeAddsImages = ({ state }) => {
  const URL = GET_IMAGE;
  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages(state);
  }, [state]);

  return (
    <>
      {/* <StyleTypeAddsImages> */}

      {/* {(images.length && images) ?? ( */}
      <StyledImagesWrapper>
        {images.length ? (
          <>
            {/* <StyledImagePrewie> */}
            {images.map((img, index) => {
              return (
                <>
                  <Image size={48} src={`${URL}${img}`} />
                </>
              );
            })}
            {/* </StyledImagePrewie> */}
          </>
        ) : (
          <>
            <Text weight="3">
              <BadgeCastom style="bad">Не картинок</BadgeCastom>
            </Text>
          </>
        )}
      </StyledImagesWrapper>
      {/* </StyleTypeAddsImages> */}
    </>
  );
};

export default TypeAddsImages;
