import { useEffect, useState } from "react";
import {
  NEW_TASK,
  IN_PROGRESS,
  UNDER_REVIEW,
  COMPLETED,
  REVISE,
} from "../constants/status";
import { userStore } from "../stores/UserStore";
import { observer } from "mobx-react-lite";
import { taskStore } from "../stores/TaskStore";
import {
  Switch,
  Text,
  Select,
  Button,
  Accordion,
} from "@telegram-apps/telegram-ui";
import { GetExecutorTasks } from "../api/api";
import { ADMIN } from "../constants/roles";
import UserFinderInput from "./UserFinderInput";
import { autorun } from "mobx";
import SwithWrapper from "../wrappers/SwithWrapper";

const TaskFilter = observer(() => {
  const [tgUserId, setTgUserId] = useState(null);
  const [user, setUser] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState("");
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //   // if (userStore.user.role === ADMIN) {
    //   //   setTgUserId(null);
    //   // } else {
    //   //   setTgUserId(userStore.user?.tg_user_id);
    //   // }
    //   // if (status != status || completed != completed) {
    //   // console.log(status);
    //   // console.log(completed);
    //   // }
  }, [user]);

  useEffect(() => {
    const dispose = autorun(() => {
      setUser(userStore.user);
      if (userStore.user?.role !== ADMIN) {
        setTgUserId(userStore.user?.tg_user_id);
      }
      //    handelGetTasks();
      //  }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);

  async function handelGetTasks() {
    setLoading(true);
    let res = await GetExecutorTasks(tgUserId, completed, status);
    console.log(res);
    if (res) {
      taskStore.setTasks(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Accordion expanded={open} onChange={() => setOpen(!open)}>
        <Accordion.Summary>Фильтр</Accordion.Summary>
        <Accordion.Content>
          {user?.role === ADMIN && (
            <>
              <UserFinderInput
                onSingleSelectChange={(e) => setTgUserId(e?.tg_user_id || null)}
              />
            </>
          )}
          <SwithWrapper>
            <Switch
              checked={completed}
              onChange={(e) => setCompleted(e.target.checked)}
            />
            <Text weight="3">Завершена</Text>
          </SwithWrapper>
          <Select
            header="Статус"
            value={status}
            placeholder="Выбери статус"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={undefined}></option>
            <option value={NEW_TASK}>{NEW_TASK}</option>
            <option value={IN_PROGRESS}>{IN_PROGRESS}</option>
            <option value={UNDER_REVIEW}>{UNDER_REVIEW}</option>
            <option value={COMPLETED}>{COMPLETED}</option>
            <option value={REVISE}>{REVISE}</option>
          </Select>
          {/* <div>TaskFilter</div> */}
          <Button
            stretched={true}
            loading={loading}
            onClick={() => {
              handelGetTasks();
            }}
          >
            Применить
          </Button>
        </Accordion.Content>
      </Accordion>
    </>
  );
});

export default TaskFilter;
