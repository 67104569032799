import styled from "styled-components";
import {
  IN_PROGRESS,
  UNDER_REVIEW,
  NEW_TASK,
  COMPLETED,
  REVISE,
} from "../constants/status";

const StyledBadgeCastomGood = styled.span`
  padding: 4px 8px;
  background: color-mix(in srgb, var(--tgui--green) 30%, transparent);
  color: var(--tgui--green);
  border-radius: 8px;
`;
const StyledBadgeCastomBad = styled.span`
  padding: 4px 8px;
  background: color-mix(
    in srgb,
    var(--tgui--destructive_text_color) 30%,
    transparent
  );
  color: var(--tgui--destructive_text_color);
  border-radius: 8px;
`;

const StyledBadgeCastomDefault = styled.span`
  padding: 4px 8px;
  background: color-mix(
    in srgb,
    var(--tgui--accent_text_color) 20%,
    transparent
  );
  color: var(--tgui--accent_text_color);
  border-radius: 8px;
`;

const StyledBadgeNew = styled.span`
  padding: 4px 8px;
  background: color-mix(in srgb, #f7b627 20%, transparent);
  color: #f7b627;
  border-radius: 8px;
`;
const StyledBadgeProgress = styled.span`
  padding: 4px 8px;
  background: color-mix(
    in srgb,
    var(--tgui--secondary_code_highlight) 20%,
    transparent
  );
  color: var(--tgui--secondary_code_highlight);
  border-radius: 8px;
`;
const BadgeCastom = ({ style, children }) => {
  return (
    <>
      {style == "good" && (
        <StyledBadgeCastomGood>{children}</StyledBadgeCastomGood>
      )}
      {style == "bad" && (
        <StyledBadgeCastomBad>{children}</StyledBadgeCastomBad>
      )}
      {children === IN_PROGRESS && (
        <StyledBadgeProgress>{children}</StyledBadgeProgress>
      )}
      {children === UNDER_REVIEW && (
        <StyledBadgeCastomDefault>{children}</StyledBadgeCastomDefault>
      )}

      {children === NEW_TASK && <StyledBadgeNew>{children}</StyledBadgeNew>}

      {children === COMPLETED && (
        <StyledBadgeCastomGood>{children}</StyledBadgeCastomGood>
      )}
      {children === REVISE && <StyledBadgeNew>{children}</StyledBadgeNew>}
      {/* {!style && (
       
      )} */}
    </>
  );
};
export default BadgeCastom;
