import styled from "styled-components";

const StyledCardTextWrapper = styled.div`
  row-gap: 0.1rem;
  display: flex;
  flex-direction: column;
`;

// Компонента-обертка
const CardTextWrapper = ({ children }) => {
  return <StyledCardTextWrapper>{children}</StyledCardTextWrapper>;
};

export default CardTextWrapper;
