import React, { useEffect, useState, useCallback, Suspense } from "react";
import { Button, Modal, Section, Select } from "@telegram-apps/telegram-ui";
import ContentWrapper from "./ContentWrapper";
import TaskAdminModalSwith from "./TaskAdminModalSwith";
import { taskStore } from "../stores/TaskStore";
import { userStore } from "../stores/UserStore";
import { observer } from "mobx-react-lite";
import {
  NEW_TASK,
  IN_PROGRESS,
  UNDER_REVIEW,
  COMPLETED,
  REVISE,
} from "../constants/status";
import { changeTasksStatus, addtTasksComplete } from "../api/api";

const CardTaskAdminModal = observer(({ state }) => {
  const [open, setOpen] = useState(false);
  const [taskContent, setTaskContent] = useState(null);

  useEffect(() => {
    setTaskContent(state);
  }, [state]);

  async function handelchangeTasksStatus(status) {
    let newStatus = await changeTasksStatus(
      userStore.user?.tg_user_id,
      state.id,
      status
    );
    console.log(newStatus);
    if (newStatus) taskStore.updateTaskStatus(state.id, status);
  }

  async function handeTasksComplete() {
    console.log(state.id);
    const newTask = await addtTasksComplete(state.id);
    if (newTask) {
      taskStore.updateTaskStatus(state.id, newTask.status);
      taskStore.updateTaskComplated(state.id, newTask.completed);
    }
  }
  // addtTasksComplete;

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        Проверить задачу
      </Button>
      <Modal
        onOpenChange={(open) => {
          setOpen(open);
        }}
        open={open}
      >
        <Section footer="Версия: s1.0.1" header="Проверка задачи">
          <ContentWrapper>
            <Select
              header="Статус"
              value={state.status}
              placeholder="Выбери статус"
              onChange={(e) => handelchangeTasksStatus(e.target.value)}
            >
              <option>{NEW_TASK}</option>
              <option>{IN_PROGRESS}</option>
              <option>{UNDER_REVIEW}</option>
              <option>{COMPLETED}</option>
              <option>{REVISE}</option>
            </Select>

            {taskContent?.typeAdds != null &&
              taskContent?.typeAdds != undefined && (
                <>
                  {taskContent?.typeAdds.map((task) => {
                    return (
                      <TaskAdminModalSwith
                        showImg={true}
                        key={state.id}
                        taskId={state.id}
                        task={task}
                        task_result={taskContent.task_result}
                      />
                    );
                  })}
                </>
              )}
            <Button
              onClick={() => {
                handeTasksComplete();
              }}
            >
              Завершить задачу
            </Button>
          </ContentWrapper>
        </Section>
      </Modal>
    </>
  );
});
export default CardTaskAdminModal;
