import React, { useEffect, useState } from "react";
import { userStore } from "../stores/UserStore";
import { GetExecutorTasks } from "../api/api";
import { autorun } from "mobx";
import CardTask from "../components/CardTask";
import ContentWrapper from "../components/ContentWrapper";
import { Spinner } from "@telegram-apps/telegram-ui";
import { taskStore } from "../stores/TaskStore";
import { observer } from "mobx-react-lite";
import TaskFilter from "../components/TaskFilter";

const TaskUser = observer(() => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handelGetTasks = async () => {
    setLoading(true);
    if (userStore.user?.tg_user_id) {
      let res = await GetExecutorTasks(
        userStore.user.tg_user_id,
        completed,
        ""
      );
      if (res) {
        taskStore.setTasks(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const dispose = autorun(() => {
      if (userStore.user?.tg_user_id) {
        handelGetTasks();
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);

  return (
    <>
      <ContentWrapper>
        <TaskFilter />
        {loading ? (
          <Spinner size="m" />
        ) : (
          <>
            {taskStore.tasks.length ? (
              <>
                {taskStore.tasks.map((element, index) => {
                  return (
                    <>
                      {/* {JSON.stringify(element.task_result)}. */}
                      <CardTask
                        key={element.id}
                        state={element}
                        tg_user_id={userStore.user?.tg_user_id}
                      />
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <p>Данных нет</p>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </>
  );
});

export default TaskUser;
