import { Placeholder } from "@telegram-apps/telegram-ui";
import ContentWrapper from "../components/ContentWrapper";

const NotFound = () => {
  return (
    <>
      <ContentWrapper>
        <Placeholder header="403" description="Доступ запрещен"></Placeholder>
      </ContentWrapper>
    </>
  );
};

export default NotFound;
