import React, { useEffect, useState } from "react";
import {
  Title,
  Text,
  Cell,
  Button,
  Section,
  Caption,
  Modal,
  Switch,
  Input,
} from "@telegram-apps/telegram-ui";
import { observer } from "mobx-react-lite";
import ContentWrapper from "../components/ContentWrapper";
import { userStore } from "../stores/UserStore";
import { autorun } from "mobx";
import UserFinderInput from "../components/UserFinderInput";
import TypeAddInput from "../components/TypeAddInput";
import { CreateNewTask, TasksAssignUser, TaskAddType } from "../api/api";
import { taskStore } from "../stores/TaskStore";
import { toast } from "react-hot-toast";
import AdressesInput from "../components/AdressesInput";

const CreateTask = observer(() => {
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0, поэтому добавляем 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [executorid, setExecutorId] = useState(null);
  const [typeAdds, setTypeAdds] = useState(null);
  const [date, setDate] = useState(getCurrentDate());
  const [address, setAddress] = useState(null);

  useEffect(() => {
    const dispose = autorun(() => {
      if (userStore.user?.tg_user_id) {
        setUserId(userStore.user?.tg_user_id);
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);

  async function handleCreateNewTask() {
    setLoading(true);
    const pauloud = {
      date: date,
      address: address,
      tg_user_id: userId,
    };
    if (typeAdds != null && executorid) {
      let newTask = await CreateNewTask(pauloud);

      if (newTask) {
        let addUser = await TasksAssignUser(newTask.id, executorid);
        console.log(await addUser);
        let addType = await TaskAddType(newTask.id, typeAdds);
        console.log(await addType);
        taskStore.add(addType);
        setLoading(false);
      }
    } else {
      toast.error("Не все поля заполнены");
      setLoading(false);
    }
  }

  return (
    <>
      <Text weight="2">Создание задачи</Text>
      <UserFinderInput onSingleSelectChange={(e) => setExecutorId(e?.id)} />
      <TypeAddInput onSelectChange={(e) => setTypeAdds(e)} />
      <Input
        disabled={loading}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        type="date"
        header="Дата"
      />
      {/* <Input
        disabled={loading}
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        type="text"
        header="Адрес"
        placeholder="Ул Пушкина"
      /> */}

      <AdressesInput value={address} onChange={(e) => setAddress(e)} />

      <Button
        loading={loading}
        onClick={() => handleCreateNewTask()}
        disabled={loading}
      >
        Создать
      </Button>
    </>
  );
});

export default CreateTask;
