import { makeAutoObservable } from 'mobx';

class TaskStore {
    tasks = []; // начальное значение

    constructor() {
        makeAutoObservable(this); // делает хранилище реактивным
    }

    setTasks(tasks) {
        this.tasks = tasks; // сохраняем пользователя
    }
    add(task) {
        this.tasks.unshift(task); // add a new task
    }
    updateTask(tasksId, newTaskResult) {
        console.log(`tasks id: ${tasksId}`)
        console.log(`newTaskResult: ${newTaskResult}`)

        const existingIndex = this.tasks.findIndex(item => item.id === tasksId);
        console.log(newTaskResult)
        this.tasks[existingIndex].task_result = newTaskResult
        console.log('UPDATE')

    }

    updateTaskComplated(tasksId, completed) {
        const existingIndex = this.tasks.findIndex(item => item.id === tasksId);
        this.tasks[existingIndex].completed = completed
    }

    updateTaskStatus(tasksId, status) {
        const existingIndex = this.tasks.findIndex(item => item.id === tasksId);
        this.tasks[existingIndex].status = status
    }

    clearUser() {
        this.tasks = null; // сбрасываем пользователя
    }
}

export const taskStore = new TaskStore();
