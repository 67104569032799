import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { GET_IMAGE } from "../api/api";
import { Image } from "@telegram-apps/telegram-ui";
import ImageViewer from "react-simple-image-viewer";
const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
`;
const ImageViewerFullScreen = ({ images }) => {
  const URL = GET_IMAGE;
  const [imagesList, setImagesList] = useState(generateImagesList(images));
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  function generateImagesList(images) {
    return images.map((image) => {
      return `${URL}${image}`;
    });
  }
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <StyledImagesWrapper>
        {images.length && (
          <>
            {images.map((img, index) => {
              return (
                <Image
                  key={index}
                  onClick={() => openImageViewer(index)}
                  size={64}
                  src={`${URL}${img}`}
                />
              );
            })}
          </>
        )}
        {isViewerOpen && (
          <ImageViewer
            src={imagesList}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        {/* {JSON.stringify(imagesList)} */}
      </StyledImagesWrapper>
    </>
  );
};

export default ImageViewerFullScreen;
