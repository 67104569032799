import { Placeholder } from "@telegram-apps/telegram-ui";
import ContentWrapper from "../components/ContentWrapper";

const NotFound = () => {
  return (
    <>
      <ContentWrapper>
        <Placeholder
          header="404"
          description="Такой страницы нет"
        ></Placeholder>
      </ContentWrapper>
    </>
  );
};

export default NotFound;
