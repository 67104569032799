import React, { useEffect, useState, useRef } from "react";
import ContentWrapper from "./ContentWrapper";

import {
  Spinner,
  Cell,
  Text,
  Card,
  Button,
  Select,
  Modal,
  Switch,
} from "@telegram-apps/telegram-ui";
import { usersBanned, usersConfirmRole, usersEditeRole } from "../api/api";
import styled from "styled-components";

const SwitchWrapperStyle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
`;
const EditeUserPnnelModal = ({ tg_user_id, state, onChangeUser }) => {
  const [open, setOpen] = useState(false);
  const [banned, setBanned] = useState(state.banned);
  const [role, setRole] = useState(state.role);

  const [validateRole, setValidateRole] = useState(state.validate_role);

  async function changeUsersBanned(banned) {
    try {
      const newUser = await usersBanned(tg_user_id, banned);
      if (newUser) {
        setBanned(banned);
        if (onChangeUser) {
          onChangeUser(newUser);
        }
      }
    } catch (error) {
      console.error("Ошибка при обновлении пользователя:", error);
      throw error; // Пробрасываем ошибку, если нужно обработать ее выше
    }
  }
  async function changeEditeRole(role) {
    try {
      const newUser = await usersEditeRole(tg_user_id, role);
      if (newUser) {
        setRole(role);
        if (onChangeUser) {
          onChangeUser(newUser);
        }
      }
    } catch (error) {
      console.error("Ошибка при обновлении пользователя:", error);
      throw error; // Пробрасываем ошибку, если нужно обработать ее выше
    }
  }
  async function changeConfirmRole(validate) {
    try {
      const newUser = await usersConfirmRole(tg_user_id, validate);
      if (newUser) {
        setValidateRole(validate);
        if (onChangeUser) {
          onChangeUser(newUser);
        }
      }
    } catch (error) {
      console.error("Ошибка при обновлении пользователя:", error);
      throw error; // Пробрасываем ошибку, если нужно обработать ее выше
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        mode="bezeled"
        stretched={true}
      >
        Изменить
      </Button>{" "}
      <Modal
        onOpenChange={(open) => {
          setOpen(open);
        }}
        open={open}
      >
        <ContentWrapper>
          {/* {JSON.stringify(state)} */}

          <Select
            onChange={(e) => changeEditeRole(e.target.value)}
            header="Роль"
            value={role}
            placeholder="Установить роль"
          >
            <option>User</option>
            <option>Admin</option>
          </Select>
          <SwitchWrapperStyle>
            <Switch
              checked={validateRole}
              onChange={(e) => changeConfirmRole(e.target.checked)}
            />
            Роль подтверждена
          </SwitchWrapperStyle>

          <SwitchWrapperStyle>
            <Switch
              checked={banned}
              onChange={(e) => changeUsersBanned(e.target.checked)}
            />
            Забанить пользователя
          </SwitchWrapperStyle>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default EditeUserPnnelModal;
