import React, { useState } from "react";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import styled from "styled-components";
import "react-html5-camera-photo/build/css/index.css";
import { FaRegTrashCan, FaCamera } from "react-icons/fa6";
import { Button, Modal, Image, IconButton } from "@telegram-apps/telegram-ui";
import { handleUpload } from "../api/api";
import { toast } from "react-hot-toast";

const CameraWrapper = styled.div`
  width: 100%;
  heigth: 900px;
`;
const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const StyledImagePrewie = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: center;
`;
const CastomCamera = ({ tg_user_id, typeAddId, taskId, onSingleAddImages }) => {
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);

  const handleRemoveFile = (index) => {
    setPhotos((prevFiles) => {
      if (prevFiles[index].preview)
        URL.revokeObjectURL(prevFiles[index].preview);
      return prevFiles.filter((_, i) => i !== index);
    });
  };

  useState(() => {
    if (onSingleAddImages) {
      onSingleAddImages();
    }
  }, [photos]);

  function savePhotoAsFile(dataUri) {
    // Преобразуем dataUri в Blob
    const byteString = atob(dataUri.split(",")[1]);
    const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
    const buffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: mimeString });

    // Сохраняем Blob как файл
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    setPhotos((e) => [link.href, ...e]);
  }

  function savePhotoAsFile(dataUri) {
    // Преобразуем dataUri в Blob
    const byteString = atob(dataUri.split(",")[1]);
    const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0];
    const buffer = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: mimeString });
    const file = new File([blob], `photo-${Date.now()}.jpg`, {
      type: mimeString,
    });
    const preview = URL.createObjectURL(blob);

    // Добавляем объект с файлом и превью в массив photos
    setPhotos((prevPhotos) => [...prevPhotos, { file, preview }]);
  }

  const UploadImage = async () => {
    setLoadingSend(true);
    if (photos.length > 10) {
      toast("Не больше 10 фото за раз", { type: "error" });
      return setLoadingSend(false);
    } else {
      let resUploaded = await handleUpload(
        tg_user_id,
        typeAddId,
        taskId,
        photos
      );
      if (resUploaded) {
        console.log(resUploaded);
        setLoadingSend(false);
        setPhotos([]);
      } else {
        setLoadingSend(false);
      }
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <FaCamera />
      </Button>

      <Modal
        onOpenChange={(open) => {
          setOpen(open);
        }}
        open={open}
      >
        <CameraWrapper>
          <Camera
            isSilentMode={true}
            sizeFactor={0.5}
            idealFacingMode={FACING_MODES.ENVIRONMENT} // Установить заднюю камеру
            onTakePhoto={(dataUri) => {
              savePhotoAsFile(dataUri);
            }}
          />
        </CameraWrapper>
        <StyledImagesWrapper>
          {photos.map((href, idx) => (
            <>
              <StyledImagePrewie>
                <Image size={48} src={href.preview} />
                <IconButton
                  mode="bezeled"
                  size="m"
                  onClick={() => handleRemoveFile(idx)}
                >
                  <FaRegTrashCan />
                </IconButton>
              </StyledImagePrewie>
            </>
          ))}
        </StyledImagesWrapper>
        <br />
        <Button
          size="m"
          stretched={true}
          loading={loadingSend}
          onClick={() => UploadImage()}
        >
          Сохранить
        </Button>
      </Modal>
    </>
  );
};
export default CastomCamera;
