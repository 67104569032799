import axios from 'axios'
import { toast } from 'react-hot-toast';
import { getTokenFromCookie } from '../cookie/cookie'


export const URL = 'https://code-learn.ru/distributorsApp'
export const GET_IMAGE = "https://code-learn.ru/uploads/";
export const URL_SOCET = "https://code-learn.ru"
// socket /
// export const URL = "http://localhost:3001/distributorsApp"
// export const URL_SOCET = "http://localhost:3001"
// export const GET_IMAGE = "http://localhost:3001/distributorsApp/images/";

// <script src = "https://telegram.org/js/telegram-web-app.js" ></script>

// export const URL


// Создаем кастомный инстанс axios
const apiClient = axios.create({
    baseURL: URL, // Базовый URL для всех запросов
});

// Добавляем request interceptor
apiClient.interceptors.request.use(
    (config) => {
        // Получаем токен (например, из localStorage или cookies)
        const token = getTokenFromCookie();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Добавляем токен в заголовки
        }
        return config; // Возвращаем измененный конфиг
    },
    (error) => {
        // Обрабатываем ошибки перед отправкой запроса
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        // Обрабатываем успешный ответ
        return response;
    }, (error) => {
        if (error.response && error.response.status === 401) {
            toast(`Неавторизован! : ${error.message}`, { type: "error" });
            window.location.href = '/403';
        } else if (error.response && error.response.status === 403) {
            toast(`Доступ запрешен! : ${error.message}`, { type: "error" });
            window.location.href = '/403';
        }
    })


export async function addtTasksComplete(taskId) {
    try {
        const response = await apiClient.post(`${URL}/tasks/complete/${taskId}`);
        toast("Задача завершена")

        return {
            completed: response.data.completed,
            status: response.data.status,
        }

    } catch (error) {
        console.error(error);
        toast(`Неаудалось завершить задачу: ${error.message}`, { type: "error" });
        return null
    }

}



export async function addPassedTask(taskId, typeAddId, value) {
    try {
        const response = await apiClient.patch(`${URL}/tasks/toggle-result/${taskId}`, {

            typeAddId: typeAddId,
            passed: value

        });
        toast("Значение изменено")

        return response.data.task_result
    } catch (error) {
        console.error(error);
        toast(`Неаудалось изменить значение: ${error.message}`, { type: "error" });
        return null
    }

}


export async function CreateNewTask(data) {
    try {
        const response = await apiClient.post(`${URL}/tasks/create`, {
            date: data.date,
            address: data.address,
            tg_user_id: data.tg_user_id,
            completed: false,
            image: null,
            taskType: null
        });
        toast("Задача успешно создана!")
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось создать задачу: ${error.message}`, { type: "error" });
        return null
    }
}


export async function usersBanned(tg_user_id, banned) {
    try {
        const response = await apiClient.put(`${URL}/users/banned`, {

            tg_user_id: tg_user_id,
            banned: banned

        });
        toast("Статус бана изменен")
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось забанить: ${error.message}`, { type: "error" });
        return null
    }
}

export async function usersConfirmRole(tg_user_id, validate) {
    try {
        const response = await apiClient.put(`${URL}/users/confirm/role`, {
            tg_user_id: tg_user_id,
            validate: validate
        });
        toast("Подтверждение роли изменено")
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось забанить: ${error.message}`, { type: "error" });
        return null
    }
}

export async function usersEditeRole(tg_user_id, role) {
    try {
        const response = await apiClient.put(`${URL}/users/edite/role`, {
            tg_user_id: tg_user_id,
            role: role
        });
        toast("Роль изменена")

        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось забанить: ${error.message}`, { type: "error" });
        return null
    }
}



export async function findAdress(value) {
    const key = "f445662c-54fd-4401-b676-864919776a8f"

    try {
        const response = await apiClient.get(`https://catalog.api.2gis.com/3.0/items?q=${value}&key=${key}`);
        // toast("Роль изменена")
        if (response.data?.meta?.code === 200) {
            return response.data
        } else {
            toast(`Данных по такому запросу нет`, { type: "error" });
            return response.data
        }

    } catch (error) {
        console.error(error);
        toast(`Неаудалось получить адреса: ${error.message}`, { type: "error" });
        return null
    }
}




// Назначение юзеру
export async function TasksAssignUser(taskId, tg_user_id) {
    try {
        const response = await apiClient.post(`${URL}/tasks/assign`, {
            taskId: taskId,
            tg_user_id: tg_user_id,
        });
        toast("Задача назначена")
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось назначит задачу задачу: ${error.message}`, { type: "error" });
        return null
    }
}
// Привязка типов рекламы
export async function TaskAddType(taskId, typeAddList) {
    try {
        const typeAddListData = typeAddList.map((e) => e.id)
        console.log(typeAddListData)
        const response = await apiClient.put(`${URL}/tasks/add/type-add`, {
            taskId: taskId,
            typeAddIds: typeAddListData,
        });
        toast("Тип рекламы добавлен")

        return response.data
    } catch (error) {
        console.error(error);
        toast(`Неаудалось назначит задачу задачу: ${error.message}`, { type: "error" });
        return null
    }

}


// Поиск юзеров
export async function FindTypeAdd(archive) {
    try {
        const response = await apiClient.get(`${URL}/type-add/get/all?archive=${archive}`);
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}


export async function FindUser() {
    try {
        const response = await apiClient.get(`${URL}/users/get/all`);
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}

//Логин
export async function Login(tg_user_id) {
    try {
        const response = await apiClient.post(`${URL}/auth/login?tg_user_id=${tg_user_id}`);
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}

export async function EditeUser(data) {
    try {
        console.log(data)
        const response = await apiClient.put(`${URL}/users/edite`, {
            tg_user_id: data.tg_user_id,
            id: data.id,
            full_name: data.full_name,
            birth_date: data.birth_date,
            address: data.address,
            phone: data.phone
        }
        );

        toast("Данные изменены")

        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}

export async function GetExecutorTasks(tg_user_id, completed, status) {
    try {
        const params = [
            tg_user_id != null ? `tg_user_id=${tg_user_id}` : null, // Проверка на undefined или null
            completed != null ? `completed=${completed}` : null,
            status != "" ? `status=${status}` : null,
        ]
            .filter(Boolean) // Исключаем null
            .join('&'); // Объединяем параметры через &

        const response = await apiClient.get(`${URL}/tasks/get/executor?${params}`);
        // const response = await axios.get(`${URL}/tasks/get/executor?tg_user_id=${tg_user_id}&completed=${completed}&status=${status}`
        // );
        console.log(response.data)
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}

export async function GetAdminTasks(tg_user_id) {
    try {
        const response = await apiClient.get(`${URL}/tasks/get`
        );
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка логина: ${error.message}`, { type: "error" });
        return null
    }
}


export async function changeTasksStatus(tg_user_id, taskId, status) {
    try {
        const response = await apiClient.patch(`${URL}/tasks/status?tg_user_id=${tg_user_id}&taskId=${taskId}&status=${status}`
        );
        toast(`Статус измнен`);
        return response.data
    } catch (error) {
        console.error(error);
        toast(`Ошибка установки статуса: ${error.message}`, { type: "error" });
        return null
    }
}

// tasks/get/executor?tg_user_id=6935066908&completed=false


export const handleUpload = async (tgUserId, typeAddId, taskId, uploadedFiles) => {
    console.log(taskId)
    const formData = new FormData();
    uploadedFiles.forEach((fileObj) => {
        formData.append("images", fileObj.file); // `fileObj.file` - файл, загруженный из состояния
    });


    try {
        const response = await apiClient.post(`${URL}/images/upload?tg_user_id=${tgUserId}&type_add_id=${typeAddId}&task_id=${taskId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(response.data.message);
        // toast(`Картинки успешно загруженны!`);
        return response.data
    } catch (error) {
        console.error("Error uploading images:", error);
    }
};