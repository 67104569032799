import { findAdress } from "../api/api";
import { useState } from "react";
import {
  Spinner,
  Cell,
  Input,
  Button,
  Modal,
} from "@telegram-apps/telegram-ui";
import styled from "styled-components";

const StyledOptionkWrapper = styled.div`
  margin: 1rem;
  background: var(--tgui--bg_color);
  border-radius: 12px;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.04), 0 0 2px 1px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  margin-top: 8px;
  max-height: 168px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 2rem);
`;
const StyleInputWrapper = styled.div`
  position: relative;
`;

const AdressesSettings = () => {
  const [valueAdress, setValueAdress] = useState(
    "Москва, Днепропетровская улица, 5к2"
  );
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function transformArray(items) {
    return items.map((item) => ({
      id: item.id,
      label: item.full_name,
      value: item.full_name,
    }));
  }

  async function HandelfindAdress(value) {
    setLoading(true);
    let res = await findAdress(value);
    if (res.meta.code === 200) {
      if (res?.result?.items) {
        setOpen(true);
        setOptions(transformArray(res?.result?.items));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal trigger={<Button size="m">Open modal</Button>}>
        <div>
          <Input
            before={loading && <Spinner size="s" />}
            value={valueAdress}
            onChange={(e) => {
              setValueAdress(e.target.value);
            }}
          ></Input>
          <Button
            loading={loading}
            onClick={() => HandelfindAdress(valueAdress)}
          >
            Поиск
          </Button>
        </div>
        <StyleInputWrapper>
          {open && (
            <StyledOptionkWrapper>
              {options.map((option) => {
                return (
                  <Cell
                    key={option.id}
                    onClick={() => {
                      setValueAdress(option.label);
                      setOpen(false);
                    }}
                  >
                    <p>{option.label}</p>
                  </Cell>
                );
              })}
            </StyledOptionkWrapper>
          )}
        </StyleInputWrapper>
      </Modal>

      <h1> хуета</h1>
      {/* {JSON.stringify(res)} */}
      {/* <YMaps>
        <div>My awesome application with maps!</div>
        <Map
          defaultState={{
            center: [55.751574, 37.573856],
            zoom: 9,
            controls: [],
          }}
        >
          <SearchControl options={{ float: "right" }} />
        </Map>
      </YMaps> */}
    </>
  );
};

export default AdressesSettings;
