import styled from "styled-components";

const StyledCardTaskWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.04), 0 0 2px 1px rgba(0, 0, 0, 0.02);
  background: var(--tgui--card_bg_color);
  flex-direction: column;
  row-gap: 0.5rem;
`;

// Компонента-обертка
const CardTaskWrapper = ({ children }) => {
  return <StyledCardTaskWrapper>{children}</StyledCardTaskWrapper>;
};

export default CardTaskWrapper;
