import React, { useEffect, useState, useCallback, Suspense } from "react";
import styled from "styled-components";
import { Switch, Text } from "@telegram-apps/telegram-ui";
import ImageViewerFullScreen from "./ImageViewerFullScreen";
import { addPassedTask } from "../api/api";
import { taskStore } from "../stores/TaskStore";
import { observer } from "mobx-react-lite";

const TaskEditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

const TaskEditorWrapperText = styled.div`
  display: flex;
  flex-direction: column;
`;
const TaskAdminModalSwith = observer(
  ({ task, task_result, taskId, showImg }) => {
    const [checked, setChecked] = useState(task_result);
    const [images, setImages] = useState([]);
    const [showImgBlock, setShowImgBlock] = useState(showImg || false);

    useState(() => {
      console.log("UPDATE_  task_result");
      console.log(task_result);

      GetCheked(task, task_result);
      GetTaskImages(task, task_result);
    }, [task, task_result]);

    function GetCheked(task, task_result) {
      try {
        const data = JSON.parse(JSON.stringify(task_result));
        console.log(task.id);
        if (data != null && data.length) {
          const chekedValue = data.find(
            (element) => element.typeAddId === task.id
          );
          if (chekedValue) {
            setChecked(chekedValue.passed);
          }
          return false;
        }
        return false;
      } catch (error) {
        console.error(error);
      }
    }

    function GetTaskImages(task, task_result) {
      try {
        const data = JSON.parse(JSON.stringify(task_result));
        console.log(data);
        if (data != null && data.length) {
          const item = data.find((element) => element.typeAddId === task.id);
          if (item) {
            setImages(item.images);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Флаги для отслеживания изменений
    async function handelAddPassed(value) {
      let newStateTask = await addPassedTask(taskId, task.id, value);
      //

      if (newStateTask) {
        setChecked(value);

        console.log(newStateTask);

        taskStore.updateTask(taskId, newStateTask);
      }
    }

    return (
      <>
        <TaskEditorWrapper>
          <Switch
            checked={Boolean(checked)}
            onChange={(e) => handelAddPassed(e.target.checked)}
          />
          <TaskEditorWrapperText>
            <Text weight="1">{task.name}</Text>
            <Text weight="3">{task.descripton}</Text>
          </TaskEditorWrapperText>
        </TaskEditorWrapper>
        {images.length && showImgBlock ? (
          <ImageViewerFullScreen images={images} />
        ) : (
          "Нет картинок"
        )}
      </>
    );
  }
);
export default TaskAdminModalSwith;
