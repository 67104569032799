import { findAdress } from "../api/api";
import { useEffect, useState } from "react";
import {
  Spinner,
  Cell,
  Input,
  Button,
  Modal,
} from "@telegram-apps/telegram-ui";
import styled from "styled-components";
import { FaMagnifyingGlass, FaAngleUp, FaAngleDown } from "react-icons/fa6";

const StyledOptionkWrapper = styled.div`
  margin: 1rem;
  background: var(--tgui--bg_color);
  border-radius: 12px;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.04), 0 0 2px 1px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  margin-top: 8px;
  max-height: 168px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 2rem);
  z-index: 9000;
`;
const StyleInputWrapper = styled.div`
  position: relative;
`;

const AdressesInput = ({ value, onChange }) => {
  const [valueAdress, setValueAdress] = useState(value || null);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function transformArray(items) {
    return items.map((item) => ({
      id: item.id,
      label: item.full_name,
      value: item.full_name,
    }));
  }

  useEffect(() => {
    if (onChange) {
      onChange(valueAdress);
    }
  }, [valueAdress]);

  async function HandelfindAdress(value) {
    setLoading(true);
    let res = await findAdress(value);
    if (res.meta.code === 200) {
      if (res?.result?.items) {
        setOpen(true);
        setOptions(transformArray(res?.result?.items));
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <Input
          header="Адрес"
          placeholder="Введите адрес"
          before={
            <Button
              size="s"
              loading={loading}
              onClick={() => HandelfindAdress(valueAdress)}
            >
              <FaMagnifyingGlass size={"14px"} />
            </Button>
          }
          after={
            <Button
              mode="gray"
              size="s"
              // loading={loading}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <FaAngleUp size={"14px"} />
              ) : (
                <FaAngleDown size={"14px"} />
              )}
            </Button>
          }
          value={valueAdress}
          onChange={(e) => {
            setValueAdress(e.target.value);
          }}
        ></Input>
      </div>
      <StyleInputWrapper>
        {open && (
          <StyledOptionkWrapper>
            {options.map((option) => {
              return (
                <Cell
                  key={option.id}
                  onClick={() => {
                    setValueAdress(option.label);
                    setOpen(false);
                  }}
                >
                  <p>{option.label}</p>
                </Cell>
              );
            })}
          </StyledOptionkWrapper>
        )}
      </StyleInputWrapper>
    </>
  );
};

export default AdressesInput;
