import React, { useState, useEffect } from "react";
import ContentWrapper from "../components/ContentWrapper";
import CreateTask from "./CreateTask";
import { TabsList, TabsItem } from "@telegram-apps/telegram-ui";
import CardTasksAdmin from "../components/CardTasksAdmin";
import { GetAdminTasks } from "../api/api";
import { observer } from "mobx-react-lite";
import { userStore } from "../stores/UserStore";
import { autorun } from "mobx";
import { taskStore } from "../stores/TaskStore";
import { Spinner } from "@telegram-apps/telegram-ui";

const TaskAdmin = observer(() => {
  const tabsList = [
    { id: "1", name: "Задачи" },
    { id: "2", name: " Создать задачу +" },
  ];

  const [loading, setLoading] = useState(false);

  const [selectTab, setSelectTab] = useState(tabsList[0].id);

  const handelGetTasks = async () => {
    setLoading(true);
    if (userStore.user?.tg_user_id) {
      let res = await GetAdminTasks(userStore.user.tg_user_id);
      if (res) {
        taskStore.setTasks(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    const dispose = autorun(() => {
      if (userStore.user?.tg_user_id) {
        handelGetTasks();
      }
    });

    return () => dispose(); // очистка autorun при размонтировании компонента
  }, []);
  return (
    <>
      <ContentWrapper>
        <TabsList>
          {tabsList.map((tab) => {
            return (
              <TabsList.Item
                key={tab.id}
                onClick={() => setSelectTab(tab.id)}
                selected={selectTab === tab.id ? true : false}
              >
                {tab.name}
              </TabsList.Item>
            );
          })}
        </TabsList>
        {selectTab === tabsList[0].id && (
          <>{loading ? <Spinner size="m" /> : <CardTasksAdmin />}</>
        )}
        {selectTab === tabsList[1].id && <CreateTask />}
      </ContentWrapper>
    </>
  );
});

export default TaskAdmin;
