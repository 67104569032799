import '@telegram-apps/telegram-ui/dist/styles.css';
import './App.css';
import React, { useEffect, useState } from "react";
import Home from './pages/Home'
import TypeAdd from './pages/TypeAdd';
import TaskAdmin from './pages/TaskAdmin'
import TaskUser from './pages/TaskUser'
import UsresSettings from './pages/UsresSettings'
import AdressesSettings from './pages/AdressesSettings'
import UsersPanel from './pages/UsersPanel'
import NotFound from './pages/NotFound'
import NoAccess from './pages/NoAccess'
import Menu from './components/Menu'
import { AppRoot } from '@telegram-apps/telegram-ui';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { TYPE_ADD, USER_PANEL, CREAT_TASK, TASK_ADMIN, TASK_USER, USRES_SETTINGS, ADRESSES_SETTINGS } from './constants/routes'
import { userStore } from './stores/UserStore';
import { taskStore } from "./stores/TaskStore";
import { Login } from './api/api'
import { ADMIN, USER } from "./constants/roles";
import { Toaster } from 'react-hot-toast';
import { saveTokenToCookie } from './cookie/cookie'
import { io, Socket } from 'socket.io-client';
import { URL_SOCET } from './api/api'
import { toast } from 'react-hot-toast';

const App = () => {
  const [socket, setSocket] = useState(null);
  const [initDataUnsafe, setinitDataUnsafe] = useState(false);
  const [params, setParams] = useState({});
  const tgMetodGetId = () => {
    return '6182050210'
    // return '6935066908'
  }

  const [role, setRole] = useState(USER);


  async function initUser(tg_user_id) {
    const userData = await Login(tg_user_id);
    if (userData) {
      console.log("Записали юзера в store:", userData.user);
      saveTokenToCookie(userData.token)
      userStore.setUser(userData.user);
      setRole(userData.user.role) // записываем пользователя в store
    }
  }


  useEffect(() => {

    // const urlParams = new URLSearchParams(window.location.search);
    // const allParams = {};
    // let tg_user_id = 0
    // urlParams.forEach((value, key) => {
    //   tg_user_id = value;

    // });
    // console.log(tg_user_id)
    // setParams(allParams);
    // if (params?.tg_user_id) {
    // setinitDataUnsafe(true)
    // initUser(String(tg_user_id))
    // SocketConnect(String(tg_user_id))
    // console.error("Telegram Web App SDK не загружен.");
    // }
    // setinitDataUnsafe(true)
    // initUser(tgMetodGetId())
    // SocketConnect(tgMetodGetId())
    // initUser(tgMetodGetId())
    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      //   // Подготавливаем Web App
      tg.ready();
      //   // Расширяем на весь экран
      tg.expand();
      const tg_user_id = tg?.initDataUnsafe?.user?.id || null;
      console.log(String(tg_user_id))
      setinitDataUnsafe(true)
      initUser(String(tg_user_id))
      SocketConnect(String(tg_user_id))


    } else {
      toast('Доступа нет', { type: "error" })


    }


  }, []);


  function SocketConnect(tg_user_id) {
    // Подключение к серверу
    // const newSocket = io('https://code-learn.ru', {
    //   path: '/socket.io', // Совпадает с серверным
    //   query: {
    //     tg_user_id: tg_user_id,
    //   },
    // });

    const newSocket = io(URL_SOCET, {

      query: {
        tg_user_id: tg_user_id,
      },
    });
    // URL вашего сервера WebSocket
    // setSocket(newSocket);
    // 'https://code-learn.ru', {
    //   path: '/socket.io', // Путь для подключения (должен совпадать с сервером)
    //   transports: ['websocket', 'polling'], // Разрешённые транспорты
    // }

    // Обработчики событий подключения
    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server:', newSocket);
    });

    newSocket.on('sendTestMessage', (message) => {
      toast(`${message}`);
      console.log('Received message:', message); // Получаем и выводим сообщение от сервера
    });


    newSocket.on('sendFotoStatus', (message) => {
      if (message.status == 'process') {
        toast(`Загружаем фото`);
      }
      if (message.status == 'loaded') {
        toast(`Фото загруженны`, { type: "success" });
        taskStore.updateTask(message.data.taskId, message.data.dataIamges);
      }

      // status: 'process'
      // 
      console.log('Received message:', message); // Получаем и выводим сообщение от сервера
    });
  }


  // Функция для проверки наличия пользователя и его роли
  const isUserRole = (userRole, role) => userRole && userRole === role;
  return (
    <>
      <AppRoot
        appearance="dark"
      >
        {/* <pre>{params.tg_user_id}</pre> */}
        <Toaster />
        <Router>
          <Menu />
          <main>
            {/* <h5 style={{ color: "red" }}
            >{JSON.stringify(role)}</h5> */}
            <Routes>
              {isUserRole(role, USER) && (
                <>
                  {/* <Route path={TASK_USER} element={<TaskUser />} /> */}
                  <Route path="/" element={<TaskUser />} />
                  <Route path={USRES_SETTINGS} element={<UsresSettings />} />
                </>
              )}
              {isUserRole(role, ADMIN) && (
                <>
                  <Route path="/" element={<TaskAdmin />} />
                  <Route path={TYPE_ADD} element={<TypeAdd />} />
                  {/* <Route path="/" element={<TaskUser />} /> */}
                  <Route path={USRES_SETTINGS} element={<UsresSettings />} />
                  <Route path={USER_PANEL} element={<UsersPanel />} />
                  <Route path={ADRESSES_SETTINGS} element={<AdressesSettings />} />
                </>
              )}
              <Route path="/403" element={<NoAccess />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </Router>
      </AppRoot>
    </>
  );
}

export default App;

{/* <Route path="/" element={<NotFound />} /> */ }
{/* 
            <Route path={CREAT_TASK} element={<CreateTask />} />

            <Route path={TASK_ADMIN} element={<TaskAdmin />} />
            <Route path={TASK_USER} element={<TaskUser />} />

            */}