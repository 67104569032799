import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import StyledCardTaskWrapper from "../wrappers/CardTaskWrapper";
import { taskStore } from "../stores/TaskStore";
import { Caption, Title } from "@telegram-apps/telegram-ui";
import BadgeCastom from "./BadgeCastom";
import CardTaskAdminModal from "./CardTaskAdminModal";
import TaskFilter from "../components/TaskFilter";
import { Spinner } from "@telegram-apps/telegram-ui";

const CardTasksAdmin = observer(() => {
  return (
    <>
      <TaskFilter />
      {taskStore.tasks.length ? (
        taskStore.tasks.map((task) => {
          return (
            <>
              <StyledCardTaskWrapper key={task.id}>
                {/* {JSON.stringify(task)} */}
                <Caption weight="3">
                  Статус:{" "}
                  {task?.completed ? (
                    <BadgeCastom style="good">Готова</BadgeCastom>
                  ) : (
                    <BadgeCastom style="bad">Не завершена</BadgeCastom>
                  )}{" "}
                  <BadgeCastom>{task?.status}</BadgeCastom>
                </Caption>

                <Caption level="1" weight="3">
                  id: {task.id}
                </Caption>
                <Caption level="1" weight="3">
                  Автор: @
                  {task?.creator_user_name ? task?.creator_user_name : "Пусто"}
                </Caption>
                <Caption level="1" weight="3">
                  Исполнитель: @
                  {task?.executor?.tg_user_name
                    ? task?.executor?.tg_user_name
                    : "Пусто"}
                </Caption>
                <Title level="3" weight="1">
                  Адрес: {task.address}
                </Title>
                <br />

                <CardTaskAdminModal state={task} />
              </StyledCardTaskWrapper>
            </>
          );
        })
      ) : (
        <Spinner />
      )}
      {/* {JSON.stringify(taskStore.tasks)} */}
    </>
  );
});
export default CardTasksAdmin;
